<template>
    <Sidebar v-model:visible="$store.state.risk.display" position="full" :baseZIndex="1000" >
        <Toast/>
		<div class="p-grid">
			<div class="p-col-12 p-md-12 p-lg-8">
				<div class="ttitle">{{$t('Detail')}}: {{title}}</div>
			</div>
			<div class="p-col-12 p-md-12 p-lg-4">
				<Button :label="$t('Home')" style="width: 30%;" icon="pi pi-home" class="p-button-secondary p-mr-2 p-mb-2" @click="Home()"/>
				<Button :label="$t('Save')" style="width: 30%;" icon="pi pi-save" class="p-button-secondary p-mr-2 p-mb-2" @click="SaveAll()" :disabled="$store.state.risk.editing.length>0"/>
				<Button :label="$t('Close')" style="width: 30%;" icon="pi pi-times" class="p-button-secondary p-mr-2 p-mb-2" @click="Closed()"/>
				<ProgressBar :value="porc" style="width: 90%;"/>	
			</div>	
            <div class="p-col-12 p-md-12 p-lg-12">
				<Registration v-if="step==0"/>
				<Analysis v-if="step==1"/>
				<Planning v-if="step==2"/>
				<Monitoring v-if="step==3"/>
				<Control v-if="step==4"/>
            </div>
			{{error}}
		</div>
    </Sidebar>
</template>

<script>
	import API from "../service/API";
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import Token from "uuid-token-generator";
	import { defineAsyncComponent } from "@vue/runtime-core";

	export default {
        components: {
			Registration: defineAsyncComponent(() => import('./risk/Registration.vue')),
			Analysis: defineAsyncComponent(() => import('./risk/Analysis.vue')),
			Planning: defineAsyncComponent(() => import('./risk/Planning.vue')),
			Monitoring: defineAsyncComponent(() => import('./risk/Monitoring.vue')),
			Control: defineAsyncComponent(() => import('./risk/Control.vue')),
        },
		props: {
			title: {
				type: Object,
				default: null,
			},
			step: {
				type: Number,
				default: 0,
			},
		},
		data() {
			return {
				i18n: null,
				url: null,
				img: null,
				porc: 0,
				heigt_sc: 0,
				editingRows: [],
				expandedRows: [],
				editing: [],
				product: null,
				error:null,
				steps: [
					'Registration',
					'Analysis',
					'Planning',
					'Monitoring',
					'Control',
				],
			}
		},
		productService: null,
		created() {
			this.i18n = useI18nPlugin();
			this.url = process.env.VUE_APP_API;
			const tokgen = new Token(256);
			this.img = tokgen.generate();
			if(this.$store.state.screen<=728){
				this.heigt_sc =  380;
			} else {
				this.heigt_sc =  500;
			}
			this.Calc();
            this.Start();
		},
		mounted() {
			
		},
		methods: {
			Home() {
				this.Closed();
				this.$router.push({ path: '/' });
			},
            Start() {
				this.$store.state.risk.detail = this.$store.state.risk.detail.filter(val => val.status_id>this.step);
				var Consult = new API('Risk',this.steps[this.step]);
                Consult.Process('Basic',{
					inf: null
				}).then(response => {
                    this.$store.commit('Loading');
					//this.error = response.result;
                    this.$store.state.risk.basic = response.result; 
				}); 
			},
			SaveAll() {
				var Consult = new API('Risk',this.steps[this.step]);
                Consult.Process('UPD',{
					inf: this.$store.state.risk
				}).then(response => {
					//this.error = response;
					this.$store.commit('Loading');
					this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
					if (response.result) {
						this.$store.state.risk.detail = response.result.filter(val => val.status_id>this.step);
						this.Calc();
					} 				
				});
			},
            Closed(){
				this.onSave();
				this.$store.state.risk.display = false;
				this.$store.state.risk.detail = [];
				this.$store.state.risk.data = null;
				this.$store.state.risk.editing = [];
			},
			onSave(event) {
				this.$emit('save-close', event);
			},
			ViewPDF(document){
				window.open(this.url+'/upload/'+document, '_blank');
			},
			Calc(){
				let porc = 0;
				let total = this.$store.state.risk.data.pend;
				let detail = this.$store.state.risk.detail;
				detail.forEach(element => {
					if(element.status_id==6){
						porc =  parseInt(porc) + 1;
					}				
				});
				if(porc>0){
					this.porc = (parseInt(porc)*100/parseInt(total)).toFixed(1);
				} else {
					this.porc = 0;
				}
			},
			
		}
	}
</script>
<style scoped lang="scss">
.cheap {
	background-color: #54a90a !important;
	color: #ffffff !important;
}

.edit_tab{
	padding: 10px 10px 10px 10px;
	font-size: 10px;
	color: red;
}

.ttitle{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
    padding: 1em 0em 1em 0.5em;
}
.text-title{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

@media (max-width: 640px) {

	
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}


				}
			}
		}
	}
}
</style>